import React from "react";
import Layout from "./layout";
import { graphql } from "gatsby";
import { data } from "autoprefixer";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ChevronRightIcon, MailIcon, PhoneIcon } from "@heroicons/react/solid";

const Dealers = ({ data }) => {
  return (
    <>
      <Layout>
        <section className="contact-options-grid">
          <div className="p-2 bg-gray-900"></div>
          <div className="p-2 bg-gray-900"></div>
          <div className="p-2 bg-gray-900"></div>
          <div className="p-2 bg-gray-900"></div>
          <div className="bg-gray-900 p-12">
            <h1 className="text-5xl text-gray-300 text-center">
              {data.prismicDealers.data.dealer_name.text}
            </h1>
          </div>
          <div className="p-2 bg-gray-900"></div>
          <div className="p-2 bg-gray-900"></div>
          <div className="p-2 bg-gray-900">
            <GatsbyImage
              image={getImage(data.prismicDealers.data.cover_image)}
              className="rounded"
            />
          </div>
          <div className="p-2 bg-gray-900"></div>
          <div className="p-2 bg-gray-900"></div>
          <div className="p-2 bg-gray-900">
            <div className="grid grid-cols-1 lg:grid-cols-2">
              <ul className="col-span-1 p-8 lg:p-12 flex flex-col space-y-3 items-start border-r-[1px] border-gray-700">
                <li className="text-2xl text-blue-500 font-regular">
                  Get Directions
                </li>
                <li className="text-lg font-light text-gray-400">
                  Get Google Maps Navigation Link to the Dealer
                </li>
                <a
                  href={data.prismicDealers.data.dealer_navigation_link.url}
                  target="_blank"
                >
                  <button className="group pt-3">
                    <ul className="flex flex-row w-auto items-center space-x-4 p-3 border-[1px] rounded border-blue-500 bg-gray-900 hover:bg-blue-500">
                      <li className="text-base text-blue-500 group-hover:text-gray-800">
                        Get Directions
                      </li>
                      <li>
                        <ChevronRightIcon className="h-6 text-blue-500 group-hover:text-gray-800" />
                      </li>
                    </ul>
                  </button>
                </a>
              </ul>
              <ul className="col-span-1 p-8 lg:p-12 flex flex-col space-y-3">
                <li className="text-2xl text-blue-500 font-regular">Address</li>
                <li className="text-lg font-light text-gray-400">
                  {data.prismicDealers.data.address}
                </li>
              </ul>
              <ul></ul>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2">
              {/* <ul className="col-span-1 p-8 lg:p-12 flex flex-col space-y-3 items-start border-r-[1px] border-gray-700">
                <li className="text-2xl text-blue-500 font-regular">
                  Contact Details
                </li>
                <a href={"tel:" + data.prismicDealers.data.mobile_number}>
                  <li className="text-lg font-light text-gray-400">
                    {data.prismicDealers.data.mobile_number}
                  </li>
                </a>
                <a
                  href={"tel:" + data.prismicDealers.data.mobile_number}
                  target="_blank"
                >
                  <button className="group pt-3">
                    <ul className="flex flex-row w-auto items-center space-x-4 p-3 border-[1px] rounded border-blue-500 bg-gray-900 hover:bg-blue-500">
                      <li className="text-base text-blue-500 group-hover:text-gray-800">
                        Call Now
                      </li>
                      <li>
                        <PhoneIcon className="h-6 text-blue-500 group-hover:text-gray-800" />
                      </li>
                    </ul>
                  </button>
                </a>
              </ul> */}
              <ul className="col-span-1 p-8 lg:p-12 flex flex-col space-y-3 border-r-[1px] border-gray-700">
                <li className="text-2xl text-blue-500 font-regular">
                  E-Mail Address
                </li>
                <li className="text-lg font-light text-gray-400">
                  {data.prismicDealers.data.e_mail_address}
                </li>
                <a
                  href={"mailto:" + data.prismicDealers.data.e_mail_address}
                  target="_blank"
                >
                  <button className="group pt-3">
                    <ul className="flex flex-row w-auto items-center space-x-4 p-3 border-[1px] rounded border-blue-500 bg-gray-900 hover:bg-blue-500">
                      <li className="text-base text-blue-500 group-hover:text-gray-800">
                        E-Mail Now
                      </li>
                      <li>
                        <MailIcon className="h-6 text-blue-500 group-hover:text-gray-800" />
                      </li>
                    </ul>
                  </button>
                </a>
              </ul>
              
              <ul>
                
              </ul>
            </div>
          </div>
          <div className="p-2 bg-gray-900"></div>
        </section>
      </Layout>
    </>
  );
};

export const query = graphql`
  query DealerQuery($slug: String) {
    prismicDealers(data: { slug: { eq: $slug } }) {
      data {
        address
        cover_image {
          gatsbyImageData
        }
        dealer_images {
          image {
            gatsbyImageData
          }
        }
        dealer_location {
          latitude
          longitude
        }
        dealer_name {
          text
        }
        dealer_navigation_link {
          url
        }
        e_mail_address
        mobile_number
        short_address
        state
      }
    }
  }
`;

export default Dealers;
